@for (tab of tabs; track $index) {
  <a
    [routerLink]="tab.href"
    routerLinkActive="border-b-[#1F2125]"
    (click)=onMenuItemClick()
    [routerLinkActiveOptions]="{ exact: true }"
    class="text-[#1F2125] opacity-80 font-tektur sm:block border-b border-transparent hover:border-[#1F2125] transition-all px-4 py-2 uppercase" i18n>
    {{tab.name}}
  </a>
}
<div (click)=onMenuItemClick()
  class="flex font-tektur uppercase justify-center items-center text-[#1F2125] border border-solid border-current py-2 px-3 cursor-pointer ml-8 max-lg:mx-4 max-lg:mt-2.5"
  routerLink="/languages"
  i18n="currentLanguageCode"
>
 EN
</div>


<!-- <a href="#" class="bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium" aria-current="page">Dashboard</a>
<a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Team</a>
<a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Projects</a>
<a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Calendar</a>

<a href="#" class="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium" aria-current="page">Dashboard</a>
<a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Team</a>
<a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Projects</a>
<a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Calendar</a> -->
