import { Component } from '@angular/core';

@Component({
  selector: 'app-donate-button',
  standalone: true,
  imports: [],
  templateUrl: './donate-button.component.html',
  styleUrl: './donate-button.component.scss'
})
export class DonateButtonComponent {

}
