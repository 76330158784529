import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  inject,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation
} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {HeaderComponent} from '../common/components/header/header.component';
import {FooterComponent} from '../common/components/footer/footer.component';
import {ScriptLoaderService} from "../common/cdk/script-loader.service";
import {AnalyticsService} from "./application/analytics.service";
import {isPlatformBrowser} from "@angular/common";
import {NoActionDirective} from "./presentation/directives/no-action.directive";
import {MetricService} from "./application/metric.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent
  ],
  template: `
    <app-header/>
    <router-outlet/>
    <app-footer/>
  `,
  providers: [ScriptLoaderService, MetricService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NoActionDirective],
  host: {
    'class': 'flex flex-col',
  }
})
export class AppComponent implements OnInit {

  private bounceTimer: any;
  private userInteracted = false;

  private readonly scriptLoaderService = inject(ScriptLoaderService);
  private readonly analyticsService = inject(AnalyticsService);
  private readonly platformId = inject(PLATFORM_ID);

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {

      const params = new URLSearchParams(window.location.search);
      const utm_source = params.get('utm_source');
      const utm_medium = params.get('utm_medium');
      const utm_campaign = params.get('utm_campaign');

      if (utm_source || utm_medium || utm_campaign) {
        this.analyticsService.logEvent('utm_campaign', {
          category: 'marketing',
          label: `source: ${utm_source}, medium: ${utm_medium}, campaign: ${utm_campaign}`,
          timestamp: new Date()
        });
      }

    }

    // this.scriptLoaderService.loadScript(
    //   'https://donorbox.org/install-popup-button.js',
    //   'donorbox-popup-button-installer'
    // );
    // this.scriptLoaderService.loadScript(
    //   'https://donorbox.org/widget.js',
    //   'donorbox-widget', {
    //     paypalExpress: 'false'
    //   }
    // );
    this.bounceTimer = setTimeout(() => {
      if (!this.userInteracted) {
        this.analyticsService.logEvent('bounce', {
          category: 'user_behavior',
          label: 'User left before 10 seconds',
          timestamp: new Date()
        });
      }
    }, 10000);

  }

  @HostListener('click')
  onUserInteraction() {
    this.userInteracted = true;
  }

  @HostListener('window:beforeunload')
  onExit() {
    if (!this.userInteracted) {
      this.analyticsService.logEvent('bounce', {
        category: 'user_behavior',
        label: 'User left before 10 seconds',
        timestamp: new Date()
      });
    }
  }


}
