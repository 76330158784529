import {Directive, HostListener, inject, OnDestroy, OnInit} from '@angular/core';
import {AnalyticsService} from '../../application/analytics.service';

@Directive({
  selector: '[trackNoAction]',
  standalone: true
})
export class NoActionDirective implements OnInit, OnDestroy {
  private clickDetected = false;
  private scrollDetected = false;
  private noActionTimer!: any;

  private readonly analyticsService = inject(AnalyticsService);

  ngOnInit() {
    this.startNoActionTimer();
  }

  private startNoActionTimer() {
    this.noActionTimer = setTimeout(() => {
      if (!this.clickDetected && this.scrollDetected) {
        this.analyticsService.logEvent('no_action', {
          category: 'user_behavior',
          label: 'User scrolled but did not click',
          timestamp: new Date()
        });
      }
    }, 30000); // 30 секунд без кліків
  }

  @HostListener('click')
  onUserClick() {
    this.clickDetected = true;
    clearTimeout(this.noActionTimer);
  }

  @HostListener('window:scroll', [])
  onUserScroll() {
    this.scrollDetected = true;
  }

  ngOnDestroy() {
    clearTimeout(this.noActionTimer);
  }
}
