<a href="http://www.stackoverflow.com/" target="_blank">
	<button [title]=""
		type="button" 
		class="button text-white transition ease-in-out delay-50 bg-emerald-700 hover:bg-emerald-600 focus:ring-4 focus:outline-none focus:ring-emerald-200 rounded-full font-medium text-sm px-3 py-2 text-center flex items-center">
		
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-4 w-auto">
			<path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
		</svg>	  

		<span class="ps-1 pe-1.5">Donate</span>
	
	</button>
</a>